.admin-dashboard-container {
    display: grid;
    justify-content: center;
    margin-top: 100px;
    grid-template-columns: auto auto;
    column-gap: 100px;
}

.active-puzzle-container {
    position: relative;
    display: grid;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: -130px;
}

.dashboard-header {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}

.puzzle-summary {
    height: 150px;
    min-width: 450px;
    padding: 0px 40px 30px 40px;
    border-radius: 10px;
    margin-bottom: 30px;
    border: 1px solid black;
}

.summary-content {
    padding-top: 25px;
    display: flex;
    justify-content: center;
    gap: 25px;
}

.riddle-word-text {
    background-color: black;
    color: white;
    border-radius: 10px;
    padding: 10px;
    border: none;
    font-size: 25px;
    font-weight: bold;
    min-width: 150px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
}

.admin-button {
    color: black;
    border-radius: 10px;
    padding: 10px;
    border: none;
    font-size: 20px;
    width: 100px;
    height: 50px;
    cursor: pointer;
    border: none;
    background-color: #e2e8fa;
    color: black;
    box-shadow: 1px 1px 3px;
}

.review {
    background-color: #7cb9e888;
}

.approve {
    background-color: #ace1ae76
}

.reject {
    background-color: #ee82ee7e;
}

.delete-button {
    background-color: #f080809d;
}

.are-you-sure-text {
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
}

.cancel-button {
    border: 1px solid black;
    border-radius: 10px;
    color: black;
    height: 50px;
    width: 120px;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
}

.action-button {
    border: 1px solid black;
    border-radius: 10px;
    color: black;
    height: 50px;
    width: 120px;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
    background-color: #DDA0DD;
}

.admin-icon {
    margin-top: -5px;
}

@media (max-width: 430px) {
    .summary-content {
        gap: 20px;
        display: grid;
        justify-content: center;
        margin-top: -20px;
    }

    .admin-button {
        color: black;
        border-radius: 10px;
        padding: 10px;
        border: none;
        font-size: 12px;
        width: 75px;
        height: 30px;
        cursor: pointer;
        border: none;
        background-color: #e2e8fa;
        color: black;
        box-shadow: 1px 1px 3px;
        margin-top: 0px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .puzzle-summary {
        height: 250px;
        padding: 10px 0px;
        min-width: 0px;
        width: 130px;
        border-radius: 10px;
        margin-bottom: 30px;
        border: 1px solid black;
    }

    .riddle-word-text {
        background-color: black;
        color: white;
        border-radius: 10px;
        padding: 10px;
        border: none;
        font-size: 15px;
        font-weight: bold;
        min-width: 100px;
        margin-left: 0px;
        margin-right: 0px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .are-you-sure-text {
        font-size: 12px;
        margin-top: 0px;
    }

    .admin-dashboard-container {
        display: grid;
        justify-content: center;
        margin-top: 100px;
        grid-template-columns: auto auto;
        column-gap: 50px;
    }
}