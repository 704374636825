.grid-center {
    display: grid;
    justify-content: center;
    grid-template-columns: auto;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.directions-animation {
    width: 500px;
    height: 375px;
    margin-top: 100px;
    background-color: #fe4e6ebf;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.animation-text {
    text-align: center;
    font-size: 30px;
    margin-top: 150px;
    font-weight: 500;
}

.homepage-header-text {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
}

.homepage-text {
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    margin-top: -20px;
}

.sign-in-button {
    width: 240px;
    height: 50px;
    border-radius: 50px;
    border: none;
    background-color: black;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.sign-in-type-text {
    margin-top: 12px;
    font-size: 20px;
}

.google-icon {
    margin-top: 12px;
}

.envelope-icon {
    margin-top: 12px;
    font-size: 22px;
}

@media (max-width: 900px) {
    .directions-animation {
        width: 400px;
        height: 350px;
    }
}

@media (max-width: 430px) {
    .directions-animation {
        width: 340px;
        height: 320px;
    }

    .animation-text {
        padding: 0px 20px;
    }
}