.container {
    margin-top: 50px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.grid-center {
    display: grid;
    justify-content: center;
    grid-template-columns: auto;
}

.instructions-container {
    margin-top: 120px;
    text-align: center;
    width: 1315px;
    height: fit-content;
    background-color: #F8F8F8;
    border-radius: 20px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 3px;
    padding-bottom: 10px;
}

.instructions-header {
    font-size: 25px;
    margin-top: 10px;
    font-weight: 500;
}

.instruction-text {
    font-size: 18px;
    text-align: left;
    margin-left: 350px;
}

.instruction-number {
    margin-right: 5px;
}

.submission-container {
    margin-top: 30px;
    text-align: center;
    width: 1315px;
    height: fit-content;
    background-color: #F8F8F8;
    border-radius: 20px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 3px;
    margin-bottom: 75px;
    padding: 10px 0px 30px 0px;
}

.caution-icon {
    font-size: 35px;
    margin-top: 4px;
    color: red
}

.instruction-text-submition {
    font-size: 18px;
    text-align: left;
    margin-left: 250px;
}

.text-indented-1 {
    margin-left: 268px
}

.checkbox-container {
    margin-left: 58px;
    margin-top: 30px;
}

.checkbox {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50px;
    background-color: green;
    margin-left: -105px;
}

input[type='checkbox'] {
    accent-color: green;
}

.checkbox-text {
    font-size: 18px;
    margin-top: 0px;
    font-weight: bold;
}

.checkbox-text-second-line {
    margin-left: -116px;
    line-height: 3ch;
}

.submit-puzzle-button {
    width: 250px;
    height: 60px;
    border-radius: 10px;
    border: none;
    background-color: #00538C;
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 20px;
    cursor: pointer;
    color: white;
    font-weight: bold;
}

.info-icon {
    margin-left: 10px;
    font-size: 20px;
    position: absolute;
    margin-top: -15px;
}

.draft {
    background-color: #63baf4;
    color: black;
    font-size: 19px;
}

.link {
    color: #004CC4;
    cursor: pointer;
    text-decoration: underline;
}

.question-container {
    margin-top: 30px;
    text-align: center;
    width: 1315px;
    height: fit-content;
    background-color: #F8F8F8;
    border-radius: 20px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 3px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
}

.question-header {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
}

.question-type {
    font-size: 30px;
    font-weight: bold;
    width: 300px;
    height: 60px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 3px;
    margin-bottom: 50px;
}

.selected {
    background-color: gold;
}

.riddle-word-input {
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: #F8F8F8;
    width: 600px;
    font-size: 30px;
    font-weight: bolder;
    padding-bottom: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
}

.question-container-flex {
    gap: 50px;
    display: flex;
    justify-content: center;
}

input:focus {
    outline: none !important;
}

.asterisk-icon {
    font-size: 11px;
    color: red;
    margin-top: 23px;
}

.asterisk-icon-in-clue {
    color: rgba(255, 0, 0, 0.609);
    font-size: 11px;
    position: absolute;
    margin-left: 55px;
    margin-top: -10px;
}

.asterisk-icon-checkbox {
    font-size: 11px;
    color: red;
    margin-top: 26px;
    margin-left: -115px;
}

.name-instructions {
    font-size: 19px;
    text-align: left;
    margin-left: 250px;
    margin-top: 0px;
    font-weight: bold;
}

.name-input {
    width: 800px;
    height: 35px;
    font-size: 18px;
    padding: 5px 20px;
    margin-left: 28px
}

.disabled {
    opacity: .3;
    cursor: not-allowed
}

.clicked {
    opacity: .5;
    cursor: not-allowed;
    background-color: #fe4e6e;
    color: black;
}

.edit-puzzle-text {
    font-size: 18px;
}

.submit-success-message {
    font-size: 25px;
    font-weight: 500;
    margin-top: 0px;
}

.submit-puzzle-button-container {
    display: flex;
    justify-content: center;
    gap: 100px
}

.checkmark-icon {
    font-size: 50px;
    color: green;
    margin: 15px;
}

.checkmark-icon-small {
    font-size: 40px;
    color: green;
    margin: 15px;
    margin-bottom: 0px;
}

.xmark-icon {
    font-size: 50px;
    color: red;
    margin: 15px;
}

.success {
    background-color: green;
    cursor: not-allowed;
}

.failure {
    background-color: #BD3039;
    cursor: not-allowed;
}

.error {
    margin-top: 150px;
    margin-bottom: 100px;
    text-align: center;
    font-size: 20px;
}

.puzzle-status {
    background-color: black;
    padding: 10px 15px;
    padding-right: 18px;
    border-radius: 10px;
    color: white;
    margin-left: 10px;
    font-size: 23px;
    text-align: center;
}

.my-puzzle-container-flex {
    gap: 50px;
    display: flex;
    justify-content: center;
}

.instructions-header-edit {
    font-size: 25px;
    margin-top: 20px;
    font-weight: 500;
}

.puzzle-action {
    width: 230px;
    height: 45px;
    background-color: none;
    color: black;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
    font-weight: 550;
    box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 10px;
}

.author-name {
    font-size: 18px;
    font-weight: 300;
    margin-top: -10px;
    text-align: center;
}

.riddle-word {
    background-color: black;
    width: 200px;
    padding: 10px 0px;
    border-radius: 10px;
    color: white;
    font-size: 23px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 550;
}

.delete-button-container {
    margin-top: 50px;
    font-size: 18px;
}

.delete-puzzle-button {
    width: 150px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 3px;
    color: black;
}

.loading {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    margin-top: 200px;
}

.cancel {
    background-color: #ACE1AF;
}

.delete {
    background-color: #F08080;
}

.for {
    font-weight: 300;
}

.queued-for-date {
    margin-top: 20px;
    margin-bottom: -20px;
    font-size: 18px;
}

.puzzle-action-restricted {
    opacity: .2;
    box-shadow: none;
    cursor: not-allowed;
}

.question-container-flex {
    gap: 75px;
}

@media (max-width: 1500px) {
    .instructions-container {
        width: 1015px;
    }

    .question-container {
        width: 1015px;
    }

    .instruction-text {
        margin-left: 170px;
    }

    .question-type {
        font-size: 25px;
        font-weight: bold;
        width: 250px;
        height: 60px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 3px;
        margin-bottom: 50px;
    }

    .submission-container {
        width: 1015px;
    }

    .instruction-text-submition {
        font-size: 18px;
        text-align: left;
        margin-left: 130px;
        padding-left: 1.4em;
        text-indent: -1.4em;
        padding: 0px 30px;
        line-height: 2ch;
    }

    .text-indented-1 {
        margin-left: 150px
    }

    .name-instructions {
        margin-left: 140px;
    }
}

@media (max-width: 1100px) {
    .instructions-container {
        width: 750px;
    }

    .question-container {
        width: 750px;
    }

    .instruction-text {
        margin-left: 40px;
        margin-right: 20px;
        font-size: 17px;
    }

    .question-type {
        font-size: 25px;
        font-weight: bold;
        width: 200px;
        height: 60px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 3px;
        margin-bottom: 50px;
    }

    .question-container-flex {
        gap: 50px;
    }

    .submission-container {
        width: 850px;
    }

    .caution-icon {
        display: none;
    }

    .instruction-text-submition {
        font-size: 18px;
        text-align: left;
        margin-left: 75px;
        padding-left: 1.4em;
        text-indent: -1.4em;
        padding: 0px 30px;
        line-height: 2ch;
    }

    .text-indented-1 {
        margin-left: 95px
    }

    .checkbox-container {
        margin-left: 20px;
        margin-top: 30px;
    }

    .checkbox-text {
        font-size: 16px;
        font-weight: bolder;
    }

    .name-instructions {
        margin-left: 80px;
        font-size: 16px;
    }

    .name-input {
        width: 700px;
    }

    .asterisk-icon-checkbox {
        font-size: 14px;
        margin-left: -100px;
    }

    .checkbox-text-second-line {
        margin-left: -105px;
        line-height: 3ch;
    }

    .puzzle-action {
        width: 200px;
    }
}


@media (max-width: 430px) {
    .submit-puzzle-button-container {
        display: grid;
        justify-content: center;
        grid-template-columns: auto;
        column-gap: -20px;
    }

    .draft {
        margin-top: -50px;
    }

    .instructions-container {
        width: 350px;
    }

    .question-container {
        width: 350px;
    }

    .question-flex-center {
        display: grid;
        grid-template-columns: auto;
        margin-top: -520px;
    }

    .instruction-text {
        margin-left: 30px;
        margin-right: 10px;
        font-size: 18px;
    }

    .question-type {
        margin-bottom: 30px;
        color: black;
    }

    .question-container-flex {
        gap: 0px;
        display: grid;
        grid-template-columns: auto;
    }

    .riddle-word-input {
        width: 250px;
    }

    .select-riddle-question {
        margin-bottom: -10px;
    }

    .submission-container {
        width: 350px;
    }

    .caution-icon {
        display: none;
    }

    .instruction-text-submition {
        font-size: 16px;
        margin-left: 50px;
    }

    .mobile-indent {
        margin-left: -5px;
    }

    .indent {
        margin-left: -20px;
    }

    .text-submission-title {
        margin-left: 60px;
    }

    .text-indented-1 {
        margin-left: 65px
    }

    .checkbox-container {
        margin-left: 25px;
        margin-top: 30px;
        border-radius: 0px;
    }

    .checkbox-text {
        font-size: 16px;
        font-weight: 900;
        max-width: 190px;
        text-align: left;
    }

    .checkbox-text-second-line {
        display: none;
    }

    .name-instructions {
        margin-left: 30px;
        max-width: 300px;
        font-size: 14px;
    }

    .name-input {
        width: 260px;
        margin-left: 0px;
        font-size: 14px;
    }

    .asterisk-icon-checkbox {
        font-size: 14px;
        margin-top: 80px;
        margin-left: -80px;

    }

    .checkbox-text-second-line {
        margin-left: -105px;
        line-height: 3ch;
    }

    .puzzle-action {
        width: 100px;
        height: 45px;
        background-color: none;
        color: black;
        border: 1px solid black;
        border-radius: 10px;
        font-size: 14px;
        cursor: pointer;
        font-weight: 550;
        box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 10px;
    }

    .asterisk-icon-in-clue {
        color: rgba(255, 0, 0, 0.609);
        font-size: 11px;
        position: absolute;
        margin-left: 15px;
        margin-top: -10px;
    }

    .riddle-word-input {
        font-size: 22px;
    }

    .edit-puzzle-text {
        font-size: 16px;
        padding: 0px 20px;
    }

    .my-puzzle-container-flex {
        gap: 15px;
    }

    .instructions-header-edit {
        font-size: 20px;
        text-align: center;
    }

    .puzzle-status {
        background-color: black;
        padding: 10px;
        width: 275px;
        border-radius: 10px;
        color: white;
        margin-left: 0px;
        font-size: 20px;
        text-align: center;
        margin-top: 0px;
        font-weight: bold;
    }

    .puzzle-status-short {
        width: 200px;
        margin-bottom: 5px;
    }

    .delete-puzzle-button {
        width: 120px;
        height: 40px;
        border-radius: 10px;
        cursor: pointer;
        font-size: 15px;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 3px;
    }

    .edit-clues-asterisk {
        margin-left: 155px;
        margin-top: 60px;
        position: absolute;
    }
}