.navbar {
    height: 75px;
    background-color: #02354e;
    position: fixed;
    top: 0px;
    width: 100vw;
    font-size: 45px;
    z-index: 20;
}

.title {
    font-weight: 600;
    text-align: center;
    margin-top: -53px;
    color: white;
}

.to-create-page {
    cursor: pointer;
}

.hamburger {
    margin-top: 15px;
    padding-left: 20px;
}

.user-profile {
    position: absolute;
    right: 100px;
    border-radius: 50px;
    background-color: #fe4e6e;
    width: 50px;
    height: 50px;
    font-size: 20px;
    text-align: center;
    top: 13px;
    cursor: pointer;
}

.user-profile-initials {
    font-size: 25px;
    margin-top: 10px;
    font-weight: 600;
    color: black;
    letter-spacing: .05ch;
}

.sign-up-or-login-button {
    position: absolute;
    right: 50px;
    border-radius: 10px;
    background-color: #02354e;
    color: white;
    border: 2px solid white;
    width: 200px;
    height: 45px;
    font-size: 19px;
    text-align: center;
    top: 16px;
    cursor: pointer;
}

.modal-style-user {
    position: absolute;
    width: 350px;
    height: 450px;
    border: 1px solid black;
    border-radius: 10px;
    background-color: white;
    z-index: 10;
    margin-top: 10px;
    right: 10px;
    margin-top: -30px;
}

.close-modal {
    background-color: white;
    border: none;
    font-size: 25px;
    float: right;
    border-radius: 10px;
}

.close-modal:hover {
    opacity: .5;
}

.account-icon-in-modal {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: blueviolet;
}

.account-icon-in-modal {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: blueviolet;
}

.initials {
    color: white;
    text-align: center;
    font-size: 27px;
    letter-spacing: .15ch;
    font-weight: 600;
    position: relative;
    top: 15px;
}

.username {
    text-align: center;
    font-weight: bolder;
    color: black;
    margin-top: 15px;
    font-size: 15px;
}

.email {
    text-align: center;
    color: black;
    margin-top: -15px;
    font-size: 15px;
}

.manage-account-button {
    background-color: white;
    border: none;
    color: black;
    padding: 5px 50px;
    text-align: center;
    display: inline-block;
    margin: 4px 2px;
    border-radius: 16px;
    border: 1px solid black;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

.manage-account-button:hover {
    background-color: whitesmoke;
}

.sign-out-button {
    background-color: white;
    border: none;
    color: black;
    padding: 5px 50px;
    text-align: center;
    display: inline-block;
    margin: 4px 2px;
    border: 1px solid black;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    cursor: pointer;
}

.sign-out-button:hover {
    background-color: whitesmoke;
}

.close-modal {
    background-color: white;
    border: none;
    font-size: 25px;
    float: right;
    border-radius: 10px;
    cursor: pointer;
}

.close-modal:hover {
    opacity: .5;
}

.side-bar {
    position: fixed;
    left: 0px;
    top: 75px;
    bottom: 0;
    width: 350px;
    box-shadow: 3px 6px 6px rgba(0, 0, 0, .25);
    background-color: white;
    padding-top: 10px;
    animation: slideInLeft;
    animation-duration: 2s;
    z-index: 10;
}

.side-bar-header {
    font-size: 15px;
    margin-left: 20px;
    font-weight: bold;
}

.side-bar-text {
    font-size: 15px;
    margin-left: 20px;
    margin-top: 20px;
    cursor: pointer;
}

.play-the-riddley {
    top: -20px;
    text-decoration: none;
    color: black;
    font-size: 15px;
    margin-left: 20px;
    position: relative;
}

.logout {
    top: -25px;
    position: relative;
}

@media (max-width: 900px) {
    .sign-up-or-login-button {
        display: none;
    }
}

@media (max-width: 1200px) {
    .side-bar {
        width: 240px;
    }
}


@media (max-width: 1000px) {
    .side-bar {
        width: 100vw;
    }

    .user-profile {
        display: none;
    }
}