.clue {
    width: 600px;
    height: 150px;
    border-radius: 5px;
    background-color: #F8F8F8;
    border-radius: 10px;
    color: white;
    margin-top: 20px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 3px;
    position: relative;
}

.clues {
    justify-content: center;
    display: flex;
    gap: 35px;
}

.textarea {
    max-width: 500px;
    max-height: 90px;
    width: 600px;
    height: 90px;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
    border: none;
}

textarea {
    resize: none;
}

.clue-0,
.clue-1,
.clue-2 {
    background-color: #dda0dd50;
}

.clue-3,
.clue-4,
.clue-5 {
    background-color: #fa807243;
}

.clue-6,
.clue-7,
.clue-8 {
    background-color: #6aa2cd53;
}

.clue-9,
.clue-10,
.clue-11 {
    background-color: #71bc7846;
}

@media (max-width: 1500px) {
    .textarea {
        max-width: 350px;
        max-height: 90px;
        width: 600px;
        height: 90px;
        border-radius: 5px;
        padding: 20px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        font-size: 18px;
        font-family: Arial, Helvetica, sans-serif;
        margin-top: 10px;
        border: none;
    }

    .clue {
        width: 450px;
        height: 150px;
        border-radius: 5px;
        border-radius: 10px;
        color: white;
        margin-top: 20px;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 3px;
    }
}

@media (max-width: 1100px) {
    .clues {
        justify-content: center;
        display: grid;
        gap: 0px;
        grid-template-columns: auto;
    }

    .clue {
        border-radius: 10px 10px 0px 0px;
    }

    .clue-description {
        margin-bottom: 20px;
        margin-top: -1px;
        border-radius: 0px 0px 10px 10px;
    }
}

@media (max-width: 430px) {
    .clue {
        width: 300px;
        height: 150px;
        border-radius: 10px 10px 0px 0px;
        color: white;
        margin-top: 20px;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 3px;
    }

    .clue-description {
        margin-bottom: 20px;
        margin-top: -1px;
        border-radius: 0px 0px 10px 10px;
    }

    .textarea {
        max-width: 250px;
        max-height: 90px;
        font-size: 15px;
    }
}