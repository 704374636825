.guideline-container {
    margin-top: 120px;
    padding: 0px 50px;
}

.guideline-title {
    text-align: center;
    font-size: 35px;
    font-weight: 600;
}

.rules-container {
    margin-top: 50px;
    max-width: 850px;
}

.rule-title {
    font-size: 25px;
    font-weight: 600;
    line-height: 45px;
    font-family: "RedditSansChocolate",
        Arial,
        "Helvetica Neue",
        Helvetica,
        sans-serif;
    color: #000;
    margin-top: -10px;
}

.rule-description {
    font-size: 20px;
    font-weight: 400;
    margin-top: -15px;
    line-height: 30px;
    margin-bottom: 50px;
}

.rule-number {
    font-size: 35px;
    font-weight: 900;
}

.highlight {
    color: #ff4500
}

@media (max-width: 430px) {
    .guideline-title {
        font-size: 23px;
    }

    .rule-title {
        font-size: 22px;
        line-height: 25px;
    }

    .rule-number {
        font-size: 27px;
        font-weight: 900;
    }

    .rules-container {
        margin-top: -10px;
    }

    .rule-description {
        font-size: 17px;
        font-weight: 400;
        margin-top: -15px;
        line-height: 30px;
        margin-bottom: 50px;
    }

    .guideline-container {
        margin-top: 120px;
        padding: 0px 20px;
    }
}